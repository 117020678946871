<template>
  <section>
    <hero-global title="Accesibilidad"/>
  </section>
  <div class="container p-1-rem p-top-0-px">
    <section>
      <p class="subtitle">ACCESIBILIDAD</p>
      <article>
        <p>El objetivo de CERMI es que todas las personas, con independencia de su discapacidad, de su edad o de sus
          problemas para acceder a la web desde tecnologías poco convencionales, puedan navegar por las páginas de
          <a target="_blank" href="http://www.cermi.es" title="Ir a página de CERMI">http://www.cermi.es</a> sin encontrar
          dificultades de acceso.
        </p>
        <br/>
        <p>Las Pautas de Accesibilidad citadas recogen tres niveles de accesibilidad:</p>
        <br/>
        <ul class="font-family-roboto p-x-1-rem">
          <li>Nivel A: es un requerimiento básico para algunos grupos de usuarios. Su incumplimiento impediría el acceso a la información de algunas personas.</li>
          <li>Nivel AA: su objetivo es eliminar importantes barreras de acceso. Su incumplimiento podría dificultar a algunas personas el acceso a la información.</li>
          <li>Nivel AAA: mejora la accesibilidad, pero su incumplimiento no impediría que los usuarios pudiesen acceder a la información.</li>
          <li>XHTML 1.0 Transitional válido</li>
          <li>Css válido</li>
          <li>Icono de nivel AA de conformidad. Pautas W3C-Wai 1.0 para contenido web accesible</li>
        </ul>
      </article>
    </section>
    <br/>
    <section>
      <p class="subtitle">NUEVAS FUNCIONALIDADES</p>
      <article>
        <p>Algunas de las funcionalidades que se han implementado en el sitio de CERMI para permitirle acceder a todos los contenidos de la web con mayor facilidad son:</p>
        <br/>
        <ul class="font-family-roboto p-x-1-rem">
          <li>Las características visuales del portal (tipo de letra, color de fuente y fondo, etc.) se han definido mediante la hoja de estilos para que el usuario pueda ajustar el texto a sus preferencias.</li>
          <li>Los tamaños de las fuentes se han definido con unidades relativas para que pueda ampliar o disminuir el tamaño de la fuente desde las opciones del navegador.</li>
          <li>Si un usuario accede a CERMI con un navegador que no soporta hoja de estilos o scripts, no supondrá una dificultad, ya que de igual manera se puede acceder a todos los contenidos.</li>
          <li>Las páginas tienen una estructura clara tanto para el usuario que puede ver todo el contenido, como para el que lee la información con un lector de pantalla, usuarios que desactivan la hoja de estilos, etc. Bajo este objetivo, se han definido los encabezados de sección, las listas y todos los elementos que ayudan a la comprensión general del sitio web.</li>
          <li>El código HTML y CSS de las páginas se ajusta a las gramáticas formales para garantizar la correcta visualización de los contenidos en distintos navegadores.</li>
        </ul>
      </article>
    </section>
    <br/>
    <br/>
  </div>
</template>
<script>
import HeroGlobal from "@/components/Global/HeroGlobal";

export default {
  name: "Accessibility",
  components: {HeroGlobal}
}
</script>

<style scoped>

</style>
